<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('courses')" :isNewButton="checkPermission('course_store')" :isColumns="true"
					@new-button-click="createFormShow()" :is-import-button="true" @import-button-click="importData"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('courses')" :isNewButton="checkPermission('course_store')" :isColumns="true"
					@new-button-click="createFormShow()" :is-import-button="true" @import-button-click="importData"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
				:exportExcel="checkPermission('course_excelexport')" @exportExcel="exportExcel">
				<b-row>
					<!-- COURSE CODE -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('course_code')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.key"></b-form-input>
						</b-form-group>
					</b-col>

					<!-- COURSE NAME -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('course_name')">
							<b-form-input type="text" v-model="datatable.queryParams.filter.name"></b-form-input>
						</b-form-group>
					</b-col>

					<!-- COURSE TYPE -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('course_type')">
							<parameter-selectbox code="course_types"
								v-model="datatable.queryParams.filter.type"></parameter-selectbox>
						</b-form-group>
					</b-col>

					<!-- COURSE STATUS -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('course_status')">
							<status-selectbox v-model="datatable.queryParams.filter.status"></status-selectbox>
						</b-form-group>
					</b-col>

					<!--  FACULTY CODE -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('faculty')">
							<faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
						</b-form-group>
					</b-col>

					<!-- DEPARTMENT CODE -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('department')">
							<department-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
								v-model="datatable.queryParams.filter.department_code"></department-selectbox>
						</b-form-group>
					</b-col>

					<!-- PROGRAM CODE -->
					<b-col sm="6" md="3">
						<b-form-group :label="$t('program')">
							<program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
								:department_code="datatable.queryParams.filter.department_code"
								v-model="datatable.queryParams.filter.program_code"></program-selectbox>
						</b-form-group>
					</b-col>
				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('new') }}
				</template>
				<template v-slot:CommonModalContent>
					<create-form @createFormSuccess="createFormSuccess" v-if="formProcess == 'create'" />
				</template>
			</CommonModal>
			<CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ changeText($t('edit')) }}
				</template>
				<template v-slot:CommonModalContent>
					<update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
				</template>
			</CommonModal>
			<CommonModal ref="importFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('import') }}
				</template>
				<template v-slot:CommonModalContent>
					<import-form @successProcess="importFormSuccess" v-if="formProcess == 'import'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";

// Pages
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";
import ImportForm from "./ImportExcel"

// Services
import CourseService from "@/services/CourseService";

// Others
import { ValidationProvider, ValidationObserver } from "vee-validate"
import qs from 'qs'

export default {
	components: {
		AppLayout,
		Header,
		HeaderMobile,

		DatatableFilter,
		Datatable,
		CommonModal,
		FacultySelectbox,
		DepartmentSelectbox,
		ParameterSelectbox,
		ProgramSelectbox,
		StatusSelectbox,

		CreateForm,
		UpdateForm,
		ImportForm,

		ValidationProvider,
		ValidationObserver
	},
	metaInfo() {
		return {
			title: this.$t('courses')
		}
	},
	data() {
		return {
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: true,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('edit'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								permission: "course_update",
								callback: (row) => {
									this.updateFormShow(row.id)
								}
							},
							{
								text: this.$t('delete'),
								class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
								permission: "course_delete",
								callback: (row) => {
									this.formDelete(row)
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: true,
					},
					{
						label: this.toUpperCase('course_code'),
						field: 'code',
						hidden: false,
						sortable: true
					},
					{
						label: this.toUpperCase('course_name'),
						field: 'name',
						hidden: false,
						sortable: true
					},

					{
						label: this.toUpperCase('course_status'),
						field: 'status',
						hidden: false,
						sortable: false
					},
					{
						label: this.changeText(this.$t('credit')),
						field: 'credit',
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('faculty'),
						field: ('faculty_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('department'),
						field: ('department_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('program'),
						field: ('program_name'),
						hidden: false,
						sortable: false
					},
					{
						label: this.toUpperCase('course_type'),
						field: ('type_name'),
						hidden: false,
						sortable: false
					},
				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: 'code',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formId: null,
			form: {},
		}
	},
	created() {
		this.filterSet();
		this.formClear();
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
				key: null,
				name: null,
				status: null,
				faculty_code: null,
				department_code: null,
				program_code: null,
				type: null,
			}
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return CourseService.getAll(config)
				.then((response) => {
					this.datatable.rows = response.data.data
					this.datatable.total = response.data.pagination.total

					this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},

		// Clear
		formClear() {
			this.formId = null
			this.formProcess = null
		},

		// Create
		createFormShow() {
			this.formId = null
			this.formProcess = 'create'
			this.$refs.createFormModal.$refs.commonModal.show()
		},
		createFormSuccess() {
			this.$refs.createFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		// Update
		updateFormShow(id) {
			this.formId = id
			this.formProcess = 'update'
			this.$refs.updateFormModal.$refs.commonModal.show()
		},
		updateFormSuccess() {
			this.$refs.updateFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},
		formDelete(row) {
			this.$swal
				.fire({
					text: this.$t('are_you_sure_you_want_to_delete_course_name_from_pool_name',{courseName:row.name}),
					showCancelButton: true,
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('no'),
				})
				.then((result) => {
					if (result.isConfirmed) {
						CourseService.del(row.id)
							.then(response => {
								this.$toast.success(this.$t('api.' + response.data.message));
								this.getRows();
								this.formClear();
							})
							.catch(error => {
								this.$toast.error(this.$t('api.' + error.data.message));
							});
					}
				})
		},

		exportExcel() {
			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return CourseService.exportExcel(config)
				.then((response) => {
					this._downloadFile(response, 'courses.xlsx')
				})
				.catch(e => {
				});
		},
		importData() {
			this.formProcess = 'import'
			this.$refs.importFormModal.$refs.commonModal.show()
		},
		importFormSuccess() {
			this.formProcess = null;
			this.$refs.importFormModal.$refs.commonModal.hide()
		}
	}
}
</script>

