<template>
	<div>
		<ValidationObserver ref="formModalValidate">
			<b-row>
				<b-col cols="12" md="4" lg="3">
					<ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('course_code')">
							<b-form-input type="text" v-model="formData.code"
							              :state="errors[0] ? false : null"/>
							<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col cols="12" md="8" lg="9">
					<b-form-group :label="$t('name')">
						<ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
							<b-input-group class="mb-1" >
								<b-form-input v-model="formData.name"
								              :state="errors[0] ? false : null"/>
							</b-input-group>
							<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
						</ValidationProvider>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ValidationProvider name="credit" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('credit')">
							<b-form-input type="number"
							              v-model="formData.credit"
							              :state="errors[0] ? false : null"/>
							<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col>
					<ValidationProvider name="grading_system_id" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('grade_scala')">
							<grade-system-selectbox v-model="formData.grading_system_id"
							                        :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12" md="4">
					<ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('faculty')">
							<faculty-selectbox v-model="formData.faculty_code"
							                   :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col cols="12" md="4">
					<ValidationProvider name="department_code" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('department')">
							<department-selectbox v-model="formData.department_code"
							                      :faculty_code="formData.faculty_code"
							                      :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col cols="12" md="4">
					<ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('program')">
							<program-selectbox v-model="formData.program_code"
							                   :faculty_code="formData.faculty_code"
							                   :department_code="formData.department_code"
							                   :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12" md="4">
					<ValidationProvider name="type" rules="" v-slot="{valid, errors}">
						<b-form-group :label="$t('course_type')">
							<parameter-selectbox v-model="formData.type"
							                     :code="'course_types'"
							                     :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col cols="12" md="4">
					<ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('course_status')">
							<status-selectbox v-model="formData.status"
							                  :validate-error="errors[0]"/>
						</b-form-group>
					</ValidationProvider>
				</b-col>
			</b-row>
			<div class="d-flex justify-content-center mt-2">
				<b-button variant="primary"
				          @click="createForm"
				          :disabled="formLoading">
					{{ $t('save') }}
				</b-button>
			</div>
		</ValidationObserver>
	</div>
</template>

<script>
	// Components
	import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import GradeSystemSelectbox from "@/components/interactive-fields/GradeSystemSelectbox";
	import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
	import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
	import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";

	// Services
	import CourseService from "@/services/CourseService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"

	export default {
		components: {
			DepartmentSelectbox,
			FacultySelectbox,
			GradeSystemSelectbox,
			ParameterSelectbox,
			ProgramSelectbox,
			StatusSelectbox,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				formData: {
					code: null,
					name: null,
					credit: null,
					grading_system_id: null,
					faculty_code: null,
					department_code: null,
					program_code: null,
					type: null,
					status: null,
				},
				formLoading: false,
			}
		},
		methods: {
			async createForm() {
                if(this.checkPermission('course_store')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        this.formLoading = true;
                        CourseService.store(this.formData)
                                     .then(response => {
                                         this.$toast.success(this.$t('api.' + response.data.message));
                                         this.$emit('createFormSuccess', true);
                                     })
                                     .catch(e => {
                                         this.showErrors(e, this.$refs.formModalValidate);
                                     })
                                     .finally(() => {
                                         this.formLoading = false
                                     })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
			}
		}
	}
</script>

